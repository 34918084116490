
import { onMounted, ref } from "vue";
import router from "@/router";
import { useStore } from "vuex";
import { useConfirm } from "primevue/useconfirm";
import { useMutations } from "@/store/helper";
import Dialog from "primevue/dialog";
import '../../assets/sass/components/DocumentosLegales/_popUpHabilitacion.scss';

export default {
  name: "PopUpHabilitacion",
  components: {
    Dialog,
  },
  setup(props) {    
    const store= useStore();
    const activo=ref(true);

    const CerrarModal = () => {
        activo.value = false;
    };

    onMounted(() => {
        activo.value = true;
    });

    return {
      store,
      CerrarModal,
      activo
    };
  }
}
