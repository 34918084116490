<template>
  <div v-if="!loading">
    <div>
      <BannerRenovacionDebidaDiligencia
        v-if="mostrarBannerDD"
        :estadoDD="estadoDD"
      ></BannerRenovacionDebidaDiligencia>
    </div>
    <h2 class="Transmision_title">Nómina Electrónica</h2>
    <center>
      <div class="plan-control trans">
        <div class="Contract_containerTitle">
          <h1 class="Contract_containerTitle_title text">Estado de mi plan</h1>
        </div>
        <div v-if="estadoPlan" class="plan-control">
          <div class="documentos-plan" style="display: inline">
            <div>
              <span class="total">{{
                normalizeNumberValidator(estadoPlan.documentosDisponibles)
              }}</span>
              <span class="disponibles">Documentos disponibles</span>
            </div>
          </div>
        </div>
      </div>
      <input
        class="Button_BColorDegraded Button_fontButtonPrincipal"
        type="button"
        @click="GotoUploadFile()"
        value="Transmitir"
      />
    </center>
    <div class="tooltip-transmission">
      <el-tooltip
        content="Organiza las columnas de la tabla para realizar búsquedas más eficientes. Si deseas organizar por múltiples columnas, haz clic en las columnas deseadas mientras mantienes presionada la tecla Ctrl."
        placement="top"
        open-delay="0"
      >
        <font-awesome-icon
          style="font-size: 27px"
          :icon="['fas', 'info-circle']"
          color="#00805d"
          class="transmission-info-icon"
        />
      </el-tooltip>
    </div>
    <div style="margin-top: 2.5rem">
      <DataTable
        responsiveLayout="scroll"
        :rows="5"
        :rowClass="getRowClass"
        :value="totales"
        removableSort
        sortMode="multiple"
      >
        <Column
          field="periodo"
          header="Periodo"
          :sortable="true"
          bodyClass="datatable-text-center"
          style="white-space: nowrap"
        >
          <template #header>
            <div
              type="button"
              :icon="['fas', 'filter']"
              label="Image"
              @click="toggleOverlayPanel"
              style="padding-right: 10px"
            >
              <font-awesome-icon
                :icon="['fas', 'filter']"
                size="sm"
                :style="{ color: filterColor }"
              />
            </div>
            <OverlayPanel class="custom-overlaypanel" ref="overlayPanel">
              <div class="periodos-list">
                <ul>
                  <li
                    v-for="(periodo, index) in periodos"
                    :key="index"
                    @click="PeriodoSeleccionado(periodo)"
                    class="periodo-item"
                  >
                    {{ periodo }}
                  </li>
                </ul>
              </div>
            </OverlayPanel>
          </template>
        </Column>
        <Column
          field="cantidadDocumentos"
          header="Cantidad documentos"
          :sortable="true"
          bodyClass="datatable-text-center"
        >
        </Column>
        <Column
          field="cantidadDocumentosAprobados"
          header="Documentos aprobados"
          :sortable="true"
          bodyClass="datatable-text-center"
        >
        </Column>
        <Column
          field="cantidadDocumentosRechazados"
          header="Documentos rechazados"
          :sortable="true"
          bodyClass="datatable-text-center"
        >
        </Column>
        <Column
          field="fechaProcesamiento"
          header="Fecha procesamiento"
          :sortable="true"
          bodyClass="datatable-text-center"
        >
        </Column>
        <Column
          field="estadoPeriodo"
          header="Estado"
          :sortable="true"
          bodyClass="datatable-text-center"
        >
        </Column>
        <Column
          field="opciones"
          header="Opciones"
          bodyClass="datatable-text-center "
        >
          <template #body="{ data }">
            <a
              v-if="
                data.estadoPeriodo == 'Rechazada' ||
                data.estadoPeriodo == 'Transmitida parcialmente' ||
                data.estadoPeriodo == 'Transmitida'
              "
              @click="GotoError(data)"
              ><font-awesome-icon :icon="['fas', 'search']" size="lg"
            /></a>

            <el-dropdown
              v-if="
                data.estadoPeriodo == 'Transmitida parcialmente' ||
                data.estadoPeriodo == 'Transmitida'
              "
            >
              <span>
                <font-awesome-icon
                  :icon="['fas', 'download']"
                  style="margin-left: 7px; color: #00805d"
                  size="lg"
                />
              </span>
              <template #dropdown>
                <el-dropdown-menu class="menuOpcionesDescarga">
                  <el-dropdown-item @click="GetCertificate(data)">
                    <font-awesome-icon
                      :icon="['fas', 'file-pdf']"
                      style="color: #d12308"
                      size="lg"
                    />
                    Certificado de transmisión
                  </el-dropdown-item>
                  <el-dropdown-item
                    @click="
                      DescargarArchivoNominasAprobadas(
                        data,
                        'NominasAprobadasExcel'
                      )
                    "
                  >
                    <font-awesome-icon
                      :icon="['fas', 'file-excel']"
                      style="color: #1e6e24"
                      size="lg"
                    />
                    Nóminas aprobadas
                  </el-dropdown-item>
                  <el-dropdown-item
                    v-if="featureFlag"
                    @click="
                      DescargarArchivoNominasAprobadas(
                        data,
                        'NominasAprobadasXml'
                      )
                    "
                  >
                    <font-awesome-icon
                      :icon="['fas', 'file-archive']"
                      style="color: #1e6e24"
                      size="lg"
                    />
                    Nóminas aprobadas XML
                  </el-dropdown-item>
                  <el-dropdown-item
                    @click="
                      DescargarArchivoNominasAprobadas(
                        data,
                        'RepresentacionesGraficas'
                      )
                    "
                  >
                    <font-awesome-icon
                      :icon="['fas', 'file-alt']"
                      style="color: #d12308"
                      size="lg"
                    />
                    Representación gráfica
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>

            <a
              v-if="data.estadoPeriodo == 'En validación'"
              @click="GotoError(data)"
              class="crud-icon"
              ><font-awesome-icon :icon="['fas', 'search']" size="lg"
            /></a>
            <span v-if="data.estadoPeriodo == 'En validación'" class="crud-icon"
              ><font-awesome-icon
                icon="sync"
                class="fa-spin"
                size="lg"
                style="color: #00805d"
            /></span>
            <a
              v-if="data.estadoPeriodo == 'En validación'"
              @click="GotoPayrollProcessingValidation()"
            >
              <img
                :src="require('@/assets/img/file-edit-icon.svg')"
                alt="icono"
                width="22"
                height="22"
                style="display: inline; margin-left: 7px"
              />
            </a>
          </template>
        </Column>
      </DataTable>
    </div>

    <Dialog
      v-model:visible="nominasFinalizadas"
      modal
      :closable="false"
      header="Importante"
      :draggable="false"
      id="p-dialog"
      class="error-dialog"
    >
      <img src="../assets/img/Warning.png" alt="icono" width="60" height="60" />

      <p>
        No se encontraron nóminas aprobadas y/o rechazadas en el proceso de
        transmisión, todas las nóminas se encuentran en validación.
      </p>

      <template #footer>
        <Button icon="pi pi-times" @click="CerrarModal" text>← Volver</Button>
      </template>
    </Dialog>

    <Dialog
      v-model:visible="informeNominasAprobadasEnProgreso"
      modal
      :closable="false"
      header="Importante"
      :draggable="false"
      id="p-dialog"
      class="error-dialog"
    >
      <img src="../assets/img/Warning.png" alt="icono" width="60" height="60" />

      <p v-text="mensajeInformeEnProceso"></p>

      <template #footer>
        <Button icon="pi pi-times" @click="CerrarModalInformeEnProgreso" text
          >← Volver</Button
        >
      </template>
    </Dialog>
  </div>
  <PopUpTratamientoDatos v-if="(!estadoTratamientoDatos || StoreVacio) && flujoAceptacionTDP" @cerrarPopUpTTD="cerrarPopUpTTD" :StoreVacio="StoreVacio"></PopUpTratamientoDatos>
  <PopUpHabilitacion v-if="!estadoTerminosCondiciones && estadoTratamientoDatos && flujoAceptacionTDP"></PopUpHabilitacion>
  <Spinner v-if="loading" type="modern"></Spinner>
</template>

<script>
import router from "@/router";
import { useStore } from "vuex";
import Dialog from "primevue/dialog";
import { ref } from "@vue/reactivity";
import Dropdown from "primevue/dropdown";
import { useMutations } from "@/store/helper";
import { onBeforeMount, onMounted } from "vue";
import OverlayPanel from "primevue/overlaypanel";
import { DescargarArchivo } from "@/helpers/helpers";
import Spinner from "@/components/layout/Spinner.vue";
import { normalizeNumberValidator } from "@/store/Payment/plans";
import "@/assets/sass/components/TransmisionNomina/FiltroPeriodo.scss";
import "@/assets/sass/views/_Plans.scss";
import EstadoDebidaDiligencia from "../models/DueDiligence/EstadoDebidaDiligencia";
import BannerRenovacionDebidaDiligencia from "@/components/DueDiligence/BannerRenovacionDebidaDiligencia.vue";
import PopUpTratamientoDatos from "@/components/DocumentosLegales/PopUpTratamientoDatos.vue";
import PopUpHabilitacion from "@/components/DocumentosLegales/PopUpHabilitacion.vue"

export default {
  name: "Transmission",
  components: {
    Spinner,
    BannerRenovacionDebidaDiligencia,
    PopUpTratamientoDatos,
    PopUpHabilitacion,
    Dialog,
    OverlayPanel,
    Dropdown,
  },
  setup() {
    var intervalState;
    const overlayPanel = ref();
    const loading = ref(false);

    const store = useStore();

    const { setStateMenu } = useMutations(["setStateMenu"]);
    setStateMenu(true);

    const { setEntradaNominaId } = useMutations(["setEntradaNominaId"]);
    const { setDataEmpleador } = useMutations(["setDataEmpleador"]);
    const { setDetalleNomina } = useMutations(["setDetalleNomina"]);
    const { setDetalleEntradaNomina } = useMutations([
      "setDetalleEntradaNomina",
    ]);

    const mostrarBannerDD = ref(false);
    const estadoDD = ref(0);
    const estadoTratamientoDatos = ref(true);
    const idDocumentoTratamientoDatos = ref();
    const estadoTerminosCondiciones = ref(true);
    const idDocumentoTerminosCondiciones = ref();
    const nominasFinalizadas = ref(false);
    const totales = ref([]);
    const periodos = ref([]);
    const estadoPlan = ref();
    const filterColor = ref("");
    const { setStateFlujoAceptacionTDP } = useMutations(["setStateFlujoAceptacionTDP"]);
    const flujoAceptacionTDP = ref(false);
    const StoreVacio = ref(false);
    let featureFlag = ref(false);

    const CerrarModal = () => {
      nominasFinalizadas.value = false;
    };

    const informeNominasAprobadasEnProgreso = ref(false);
    const CerrarModalInformeEnProgreso = () => {
      informeNominasAprobadasEnProgreso.value = false;
    };
    const mensajeInformeEnProceso = ref("");

    const toggleOverlayPanel = (event) => {
      overlayPanel.value.toggle(event);
    };

    const getRowClass = (data) => {
      return data.estadoPeriodo == "En validación" ? "tr-validacion" : "row";
    };

    const PeriodoSeleccionado = (periodo) => {
      loading.value = true;
      overlayPanel.value.hide();
      const [anio, mes] = periodo.split("-");
      ObtenerTotalesPorPeriodo(anio, mes);
    };

    function ObtenerTotalesPorPeriodo(anio, mes) {
      var params = {
        Token: localStorage.getItem("Tokensin"),
        Anio: anio,
        Mes: mes,
      };
      store
        .dispatch("ObtenerTotalesPorPeriodo", params)
        .then((response) => {
          totales.value = response.data.totales;
          periodos.value = response.data.periodos;
          periodos.value.unshift("Todos");
          estadoPlan.value = response.data.estadoPlan;
          reloadPage();
          filterColor.value = anio > 0 && mes > 0 ? "#7fc241" : "#fff";
          loading.value = false;
        })
        .catch((error) => {
          loading.value = false;
          console.log(error);
        });
    }

    function GetCertificate(data) {
      loading.value = true;
      setDataEmpleador({
        Anio: data.anio,
        Mes: data.mes,
        CantidadAprobados: data.cantidadDocumentosAprobados,
      });
      store
        .dispatch("GetCertificate", localStorage.getItem("Tokensin"))
        .then((response) => {
          const linkSource = `data:application/pdf;base64,${response.data}`;
          const downloadLink = document.createElement("a");
          const fileName = "Certificado de transmisión.pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
          window.location.herf = downloadLink.href;
          loading.value = false;
        })
        .catch((err) => {
          console.error(err);
          loading.value = false;
        });
    }

    function closeInterval() {
      window.clearTimeout(intervalState);
    }
    const reloadPage = () => {
      var transmisionEnValidacion = totales.value.filter(
        (t) => t.estadoPeriodo == "En validación"
      );
      intervalState = window.setTimeout(() => {
        if (transmisionEnValidacion.length > 0) {
          ObtenerTotalesPorPeriodo();
        }
      }, 60000);
    };

    function GotoUploadFile() {
      closeInterval();
      router.push({ name: "LoadFile" });
    }

    function GotoError(total) {
      if (
        total.cantidadDocumentosAprobados == 0 &&
        total.cantidadDocumentosRechazados == 0 &&
        total.cantidadErroresValidacion == 0
      ) {
        nominasFinalizadas.value = true;
      } else {
        closeInterval();
        setDetalleEntradaNomina({
          Anio: total.anio,
          Mes: total.mes,
          CantidadDocumentos: total.cantidadDocumentos,
          CantidadAprobados: total.cantidadDocumentosAprobados,
          CantidadErrores: total.cantidadDocumentosRechazados,
          ErroresValidacion: total.cantidadErroresValidacion,
          Periodo: total.periodo,
          Estado: total.estadoPeriodo,
        });

        setEntradaNominaId({
          EntradaNominaId: "",
          CantidadDocumentos: 0,
          CantidadAprobados: 0,
          CantidadErrores: 0,
          Periodo: "",
        });

        router.push({ name: "SummaryTransmission" });
      }
    }

    function GetFeatureFlag() {
      loading.value = true;
      const data = {
        token: localStorage.getItem("Tokensin"),
        featureFlagName: "PBI-251261",
      };
      store
        .dispatch("GetStatusFeatureFlag", data)
        .then((response) => {
          featureFlag.value = response.data;
        })
        .catch((err) => {
          loading.value = false;
          console.error(err);
        });
    }

    function MostrarOcultarBannerDD() {
      store
        .dispatch("validarEstadoParaMostrarComponenteDD")
        .then((response) => {
          estadoDD.value = response;
          if (
            response != EstadoDebidaDiligencia.AprobadaDD &&
            response != EstadoDebidaDiligencia.NoDefinido &&
            response != EstadoDebidaDiligencia.CreadaDD
          ) {
            mostrarBannerDD.value = true;
          }
        });
    }

    function ValidarAjusteNomina() {
      if (store.state.DetalleNomina.TransmisionAjuste) {
        setDetalleNomina({
          TransmisionAjuste: false,
        });
      }
    }

    function DescargarArchivoNominasAprobadas(dataEmpleador, tipoInforme) {
      loading.value = true;
      setDataEmpleador({
        Anio: dataEmpleador.anio,
        Mes: dataEmpleador.mes,
      });
      const parametros = {
        Token: localStorage.getItem("Tokensin"),
        TipoInforme: tipoInforme,
      };
      store
        .dispatch("DescargarArchivoNominasAprobadas", parametros)
        .then((response) => {
          if (response.data.mensaje) {
            mensajeInformeEnProceso.value = response.data.mensaje;
            informeNominasAprobadasEnProgreso.value = true;
          }
          if (response.data.archivo) {
            DescargarArchivo(
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              response.data.archivo.archivo,
              response.data.archivo.nombre
            );
          }
          loading.value = false;
        })
        .catch((err) => {
          console.error("DescargarArchivoNominasAprobadas", err);
          loading.value = false;
        });
    }

    function GotoPayrollProcessingValidation() {
      closeInterval();
      router.push({ name: "PayrollProcessingValidation" });
    }

    function GetKeyVaultSecrets() {
        let parametrosConsulta = {
          token: localStorage.getItem("Tokensin"),
          secret1: "PBI278537"
        };
        store
          .dispatch("GetKeyVaultSecrets", parametrosConsulta)
          .then((response) => {       
            if (response.request.status == 200) {
              const valorKeyVault = response.data[0].value;
              if(valorKeyVault == "ON")
              {
                flujoAceptacionTDP.value = true;
                setStateFlujoAceptacionTDP(true);              
              }         
            }
          })
          .catch((error) => {
            console.log(error.request.response);
          });
      }

    function consultarDocumentosTycTdp()
    {

      let ParametrosEmpleador = {
                        tipoIdentificacion: localStorage.getItem('TipoIdentificacion'),
                        numeroIdentificacion: localStorage.getItem('Identificacion'),  
                        token: localStorage.getItem('Tokensin')               
                    }
          store.dispatch('ConsultarDocumentosTyc_Ttd', ParametrosEmpleador).then(() => { 
            asignarValoresDocumentosLegales();
        }).catch((err) => {
          console.log(err);
          StoreVacio.value = true;
        })      
    }

    function asignarValoresDocumentosLegales()
    {
      estadoTratamientoDatos.value = store.state.datosTycTtd.find((doc) => doc.nombreDocumento.includes("Tratamiento de Datos Personales"))?.aprobo;
      idDocumentoTratamientoDatos.value = store.state.datosTycTtd.find((doc) => doc.nombreDocumento.includes("Tratamiento de Datos Personales"))?.id;
      estadoTerminosCondiciones.value = store.state.datosTycTtd.find((doc) => doc.nombreDocumento.includes("Terminos y Condiciones"))?.aprobo;
      idDocumentoTerminosCondiciones.value = store.state.datosTycTtd.find((doc) => doc.nombreDocumento.includes("Terminos y Condiciones"))?.id;
    }

    onMounted(() => {
      ValidarAjusteNomina();
      GetKeyVaultSecrets();
      if(flujoAceptacionTDP)
      {
        if(store.state.datosTycTtd.length === 0)
        {
          consultarDocumentosTycTdp();   
        }
        else
        {
          asignarValoresDocumentosLegales();
        }  
      }
      
      if (localStorage.getItem("TieneDatosContacto") == "false") {
        router.push({ name: "EmployerContact" });
      } else {
        ObtenerTotalesPorPeriodo();
      }
    });

    onBeforeMount(() => {
      GetFeatureFlag();
      MostrarOcultarBannerDD();
    });

    return {
      GotoUploadFile,
      GotoError,
      loading,
      closeInterval,
      GetCertificate,
      mostrarBannerDD,
      estadoDD,
      estadoTratamientoDatos,
      idDocumentoTratamientoDatos,
      estadoTerminosCondiciones,
      idDocumentoTerminosCondiciones,
      CerrarModal,
      nominasFinalizadas,
      GetFeatureFlag,
      DescargarArchivo,
      DescargarArchivoNominasAprobadas,
      informeNominasAprobadasEnProgreso,
      CerrarModalInformeEnProgreso,
      mensajeInformeEnProceso,
      featureFlag,
      GotoPayrollProcessingValidation,
      getRowClass,
      periodos,
      totales,
      overlayPanel,
      toggleOverlayPanel,
      PeriodoSeleccionado,
      filterColor,
      estadoPlan,
      normalizeNumberValidator,
      consultarDocumentosTycTdp,
      flujoAceptacionTDP,
      StoreVacio
    };
  },
  methods:{
    cerrarPopUpTTD()
    {
      this.estadoTratamientoDatos = true;
    }
  }
};
</script>