
import { ref } from "@vue/reactivity";
import { watch} from '@vue/runtime-core';
import { onMounted } from "vue";
import {useStore} from "vuex";
import{useMutations} from '@/store/helper'
import Dialog from "primevue/dialog";
import router from '@/router';
import Spinner from "@/components/layout/Spinner.vue"
import '../../assets/sass/components/DocumentosLegales/_popUpTratamientoDatos.scss';

export default {
name: 'PopUpTratamientoDatos',
components: {
    Dialog,
    Spinner
},
props: {
  StoreVacio:Boolean
},
setup(props, {emit}) {     
      const store= useStore();
      const loading = ref(false);
      const checkTTD= ref(false);
      const errorAPI= ref(false);
      const errorPDF=ref(false);
      const obligatorio= ref(false);
      const procesando=ref(false);
      const{setTratamientoDatos} = useMutations(['setTratamientoDatos']);
      const{setAprobacionDocumentosLegales} = useMutations(['setAprobacionDocumentosLegales']);
      const idDocumento = ref();
      const { setStateMenu } = useMutations(["setStateMenu"]);  

    function Validarcheck(){     
          if(checkTTD.value){
            aprobarTTD();
          }else{
            obligatorio.value=true;
            errorAPI.value=false;           
          }
          errorPDF.value=false;       
      }

      watch(()=>checkTTD.value,()=>{
        if(checkTTD.value){
          obligatorio.value=false;
          }else{
            obligatorio.value=true;
            errorAPI.value=false;
          }
          errorPDF.value=false; 
      });

      function aprobarTTD(){
        procesando.value=true;
        var data={
            Identificacion:localStorage.getItem('Identificacion'),
            Tipoidentificacion:localStorage.getItem('TipoIdentificacion'),
            IdDocumentoParaAprobar:idDocumento     
         }  
        setTratamientoDatos(data);
          store.dispatch('AprobarTTD',localStorage.getItem('Tokensin')).then(()=>{
            errorAPI.value=false;    
            errorPDF.value=false;       
            setAprobacionDocumentosLegales({id: idDocumento.value, aprobo: true});     
            emit('cerrarPopUpTTD');
          }).catch(error=>{
              console.log(error);
              errorAPI.value=true;
              errorPDF.value=false;
              loading.value = false;
              procesando.value = false; 
          });     
        }

      function obtenerTYC()
      {
        loading.value = true;
        errorPDF.value = false;
        obligatorio.value = false;
        let params = {
             id: idDocumento.value,
             nombreArchivo: "Declaración tratamiento de datos"
          };
      store.dispatch("obtenerDocumentosLegales",params)
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
          loading.value = false; 
          errorPDF.value = true;
        });
      };

      function redirigirUnauthorized()
      {
        setStateMenu(false);
        router.push('UnAutorized');
      }

      onMounted(() => {
        idDocumento.value = store.state.datosTycTtd.find((doc) => doc.nombreDocumento.includes("Tratamiento de Datos Personales"))?.id;          
      })

       
    return {
      store,
      loading,
      checkTTD,
      obtenerTYC,
      errorAPI,
      obligatorio,
      errorPDF,
      procesando,
      Validarcheck,
      idDocumento,
      redirigirUnauthorized
    };
  }
};
